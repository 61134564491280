import DatePicker from "react-datepicker";
import React from "react";
import {dateToString} from "../../util/date";

function DateSelector({dates: state, setDate, ...rest}) {
  const data = state?.data ? state.data.map(it => new Date(it)) : [];
  const selectedDate = state.selected ? new Date(state.selected) : null;

  const onChangeHandler = d => {
    setDate(d && dateToString(d));
  }
  const minDate = state.minDate ? new Date(state.minDate) : null;
  const maxDate = state.maxDate ? new Date(state.maxDate) : null;

  return (
    <DatePicker {...rest}
                disabled={(!state.data && !state.minDate) || state.loading}
                selected={selectedDate}
                onChange={onChangeHandler}
                minDate={minDate}
                maxDate={maxDate}
                includeDates={data}
                autocomplete="off"
                classNames="form-control"
                withPortal={window.innerWidth <= 500}
    />
  );
}

export default DateSelector;
