import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

function GlobalLayout({children}) {
  return <>
    <ScrollToTop/>
    {children}
    <Footer/>
  </>;
}

export default GlobalLayout;
