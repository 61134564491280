import i18n from '../../util/i18n';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next/hooks";
import logo from '../../assets/images/etc/etc-logo3.png';
import visa from '../../assets/images/etc/visa.png';
import master from '../../assets/images/etc/master.png';


function Footer() {
    useTranslation();
    return (
        <footer>
            <div className="footer section-b-space section-t-space">
                <div className="container">
                    <div className="row order-row">
                        <div className="col-xl-3 col-md-6 order-cls">
                            <div className="footer-title mobile-title">
                                <h5>contact us</h5>
                            </div>
                            <div className="footer-content">
                                <div className="contact-detail">
                                    <div className="footer-logo">
                                        <img src={logo} alt=""
                                             className="img-fluid lazyload" style={{width: '120px'}}/>
                                    </div>
                                    <ul className="contact-list">
                                        <li><i className="fas fa-map-marker-alt"/>St. Vasil Glavinov no. 3 1000 Skopje
                                        </li>
                                        <li><i className="fas fa-map-marker-alt"/>North Macedonia</li>
                                        <li><i className="fas fa-envelope"/>info@etc.mk</li>
                                        <li><i className="fas fa-phone-alt"/>00389 (0)2 3 216 100</li>
                                        <li><i className="fas fa-phone-alt"/>00389 (0)70 390 350</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="footer-space">
                                <div className="footer-title">
                                    <h5>Links</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-links">
                                        <ul>
                                            <li><Link to="/about-us">{i18n.t('MENU_ABOUT_US')}</Link></li>
                                            <li><Link to="/contact-us">{i18n.t('MENU_CONTACT')}</Link></li>
                                            <li><Link to="/login">B2B</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 order-cls">
                            <div className="footer-space">
                                <div className="footer-title">
                                    <h5>{i18n.t('TERMS_AND_PRIVACY')}</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-links">
                                        <ul>
                                            <li><Link to="/terms-and-privacy/12">{i18n.t('TRAVEL_INSURANCE')}</Link>
                                            </li>
                                            <li><Link to="/terms-and-privacy/11">{i18n.t('LEGAL_REMARKS')}</Link></li>
                                            <li><Link
                                                to="/terms-and-privacy/13">{i18n.t('DISSMISAL_RISK_INSURANCE')}</Link>
                                            </li>
                                            <li><Link
                                                to="/terms-and-privacy/5">{i18n.t('GENERAL_TRAVEL_CONDITIONS')}</Link>
                                            </li>
                                            <li><Link to="/terms-and-privacy/10">{i18n.t('PRIVACY_POLICY')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="footer-content">
                                <div className="footer-blog">
                                    <div className="media justify-content-center">
                                        <div className="img-part">
                                            <a href><img src={visa}
                                                         className="img-fluid" alt=""/></a>
                                        </div>
                                    </div>
                                    <div className="media justify-content-center">
                                        <a href><img src={master}
                                                     className="img-fluid" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="footer-social">
                                <ul>
                                    <li>< a href="https://www.facebook.com/www.etc.mk"> <i
                                        className="fab fa-facebook-f"/>
                                    </a>
                                    </li>
                                    <li><a href="https://www.instagram.com/etctravel/?hl=en"><i
                                        className="fab fa-instagram"/></a></li>
                                    <li><a href="https://twitter.com/etctravel_mk?lang=en"><i
                                        className="fab fa-twitter"/></a></li>
                                    <li><a href="!#"><i className="fab fa-google"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="copy-right">
                                <p>© 2020™ ETC Travel All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
