import React from 'react';
import GuestsPopup from "./GuestsPopup";
import useSearchBox from "../../hooks/useSearchBox";
import './customSearchBox.scss'

function ProgramSearchBox() {
    const searchConfig = useSearchBox('program');
    const {
        // params,
        // loading,
        // departures,
        // setDeparture,
        // destinations,
        // setDestination,
        // departureDates,
        // setDepartureDate,
        // returnDates,
        // setReturnDate,
        // numberOfDays,
        guests,
        // valid,
        // errors,
        canSearch,
        doSearch,
    } = searchConfig;

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-hotel" role="tabpanel"
                 aria-labelledby="pills-hotel-tab">
                <div className="search-panel">
                    <div className="search-section">
                        <div className="search-box">
                            <div className="left-part">
                                <div className="search-body title-hotel">
                                    <h6>From</h6>
                                </div>
                                <div className="search-body title-hotel">
                                    <h6>To</h6>
                                </div>
                                <div className="search-body">
                                    <h6>Departure</h6>
                                </div>
                                <div className="search-body">
                                    <h6>Return</h6>
                                </div>
                                <div className="search-body">
                                    <h6>guests</h6>
                                    <GuestsPopup guests={guests}/>
                                </div>
                                <div className="search-body">
                                </div>
                                <div className="search-body btn-search">
                                    <button disabled={!canSearch} onClick={doSearch}
                                            className="btn btn-solid color1 customSearchButton">Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgramSearchBox;
