import React from 'react';

const NewsletterForm = ({handleSubmit, handleEmailChange, email}) => {
    return (
        <form onSubmit={handleSubmit}>
            <input type="email"
                   className="form-control"
                   placeholder="Enter Your Email"
                   onChange={handleEmailChange}
                   value={email}
                   required
                   aria-label="Recipient's username"
                   aria-describedby="button-addon2"/>
            <button className="btn btn-rounded btn-sm color1"
                    type="submit"
            >
                subscribe
            </button>
        </form>
    );
};

export default NewsletterForm;
