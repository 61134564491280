import {Button} from "react-bootstrap";
import Popup from "../Popup/Popup";

const options = [
    {value: 1, label: '1 year old'},
    {value: 2, label: '2 years old'},
    {value: 3, label: '3 years old'},
    {value: 5, label: '5 years old'},
    {value: 6, label: '6 years old'},
    {value: 7, label: '7 years old'},
    {value: 8, label: '8 years old'},
    {value: 9, label: '9 years old'},
    {value: 10, label: '10 years old'},
    {value: 11, label: '11 years old'},
    {value: 12, label: '12 years old'},
    {value: 13, label: '13 years old'},
    {value: 14, label: '14 years old'},
    {value: 15, label: '15 years old'}
];


function GuestsPopup({guests}) {
    const {
        adults, setAdults, children, setChildren,
        minNumAdults, maxNumAdults, minNumChildren, maxNumChildren
    } = guests;

    const decreaseNumAdults = () => {
        if (adults > minNumAdults) {
            setAdults(adults - 1);
        }
    };

    const increaseNumAdults = () => {
        if (adults < maxNumAdults) {
            setAdults(adults + 1);
        }
    };

    const removeChild = () => {
        if (children.length > minNumChildren) {
            setChildren(children.slice(0, children.length - 1));
        }
    };

    const addChild = () => {
        if (children.length < maxNumChildren) {
            setChildren([...children, 1]);
        }
    };

    const onChildAgeChange = (i, val) => {
        children[i] = val;
        setChildren([...children]);
    }

    return (
      <Popup
        right
        toggle={
            <div className="form-control text-nowrap">{adults} ADL + {children.length} CHD</div>
        }
      >
          <ul className="list-group">
              <li className="list-group-item">
                  <Button variant="warning" active={adults > minNumAdults} className="mr-2" onClick={decreaseNumAdults}>-</Button>
                  <strong className="mx-3">Adults: {adults}</strong>
                  <Button variant="warning" active={adults < maxNumAdults} onClick={increaseNumAdults}>+</Button>
              </li>
              <li className="list-group-item">
                  <Button variant="warning" active={children.length > minNumChildren} onClick={removeChild}>-</Button>
                  <strong className="mx-3">Children: {children.length}</strong>
                  <Button variant="warning" active={children.length < maxNumChildren} onClick={addChild}>+</Button>
              </li>
              {children.map((childAge, i) => (
                <li key={i} className="list-group-item justify-content-end">
                    <span className="mr-2 text-sm-right">Child {i+1} age</span>
                    <select value={childAge || ''} onChange={e => onChildAgeChange(i, e.target.value)}>
                        {options.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </li>
              ))}
          </ul>
      </Popup>
    );
};

export default GuestsPopup;
