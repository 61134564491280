import React from 'react';
import './App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Pages from "./pages";
import {ProvideUserContext} from "./context/user-context";
import {BrowserRouter as Router} from "react-router-dom";


function App() {
  return (
    <Router>
      <ProvideUserContext>
        <Pages/>
      </ProvideUserContext>
    </Router>
  );
}

export default App;
