import {useCallback, useEffect, useMemo, useState} from "react";
import SEARCH_CONFIG from "../config/search";
import API_URLS from "../config/api-urls";
import Api from "../classes/api";
import {useHistory} from "react-router-dom";
import NAV_URLS from "../config/nav-urls";
import {dateAddDays, dateDiffInDays, dateToString} from "../util/date";
import useUrlParams from "./useUrlParams";


/*
 * USAGE
 const {
    params,
    loading,
    departures,
    setDeparture,
    destinations,
    setDestination,
    departureDates,
    setDepartureDate,
    returnDates,
    setReturnDate,
    numberOfDays,
    guests,
    valid,
    errors,
    canSearch,
    doSearch,
  } = useSearchBox(searchType);
 */

/**
 * Loads data while maintaining state
 * @param setState
 * @param url
 * @param cached
 * @param partialUpdFn
 */
function loadData(setState, url, cached, partialUpdFn) {
  setState(s => s.loading && !s.error ? s : ({...s, loading: true, error: false}));
  Api.get(url, cached)
    .then(data => {
      if (partialUpdFn) {
        setState(s => ({...s, loading: false, selected: null, ...partialUpdFn(data, s)}));
      } else {
        setState(s => ({...s, loading: false, data, selected: null}));
      }
    })
    .catch((e) => setState(s =>  !s.loading && s.error ? s : ({...s, loading: false, error: true, reason: e.toString()})));
}

function useCustomUrlParams(userSearchType) {
  const urlParams = useUrlParams(userSearchType);

  if (!urlParams.searchType) {
    throw new Error("You are using a searchBox without specifying the search type.");
  }

  return urlParams;
}

function useDepartures({ searchType, depId }) {
  const hasDepartures = SEARCH_CONFIG[searchType]?.hasDepartures || false;
  const [state, setState] = useState({
    init: null,
    display: hasDepartures || false,
    loading: false,
    error: false,
    data: null,
    selected: null,
  });

  /** Load data on init and select if present in URL params, or default */
  useEffect(() => {
    if (searchType && state.display) {
      const { cacheDepartures } = SEARCH_CONFIG[searchType];
      const url = API_URLS.departures(searchType);
      const partialUpdFn = (data, {init, selected}) => {
        if (init !== depId) {
          selected = data.find(it => depId ? it.id === depId : it => it.default);
        }
        return { data, selected, init: depId }
      }
      loadData(setState, url, cacheDepartures, partialUpdFn);
    }
  }, [state.display, searchType, depId]);

  return [state, setState];
}

function useDestinations({ searchType, destType, destId }, departures = null) {
  const [state, setState] = useState({
    init: null,
    display: true,
    loading: false,
    error: false,
    data: null,
    selected: null,
  });

  /** Load data on init and select if present in URL params, or default */
  useEffect(() => {
    if (searchType) {
      const { cacheDestinations } = SEARCH_CONFIG[searchType];
      const initKey = destType ? destType + ',' + destId : undefined;
      if (!departures.display) {
        const url = API_URLS.destinations(searchType);
        const partialUpdFn = (data, {init, selected}) => {
          if (init !== initKey ) {
            selected = data.find(it => destType ? it.type === destType && it.id === destId : it.default);
          }
          return { data, selected, init: initKey }
        }
        loadData(setState, url, cacheDestinations, partialUpdFn);
      } else if (departures.selected) {
        const url = API_URLS.destinations(searchType, departures.selected);
        const partialUpdFn = (data, {init, selected}) => {
          if (init !== initKey ) {
            selected = data.find(it => destType ? it.type === destType && it.id === destId : false);
          }
          return { data, selected, init: initKey }
        }
        loadData(setState, url, cacheDestinations, partialUpdFn);
      } else if (!departures.selected) {
        setState(s => ({...s, error: false, data: null, selected: null}));
      }
    }
  }, [searchType, destType, destId, departures]);

  return [state, setState];
}


function useDepartureDates({ searchType, depDate }, departures, destinations) {
  const [state, setState] = useState({
    init: null,
    display: true,
    loading: false,
    error: false,
    data: null,
    selected: null,
    minDate: null,
    maxDate: null,
  });

  /** Load data on init and select if present in URL params, or default */
  useEffect(() => {
    if (searchType) {
      if ((!departures.display || departures?.selected) && destinations?.selected) {
        const { loadDates, minBookingStartDays, maxBookingStartDays } = SEARCH_CONFIG[searchType];
        if (loadDates) {
          const url = API_URLS.departureDates(searchType, departures?.selected, destinations.selected);
          const partialUpdFn = (data, {init, selected}) => {
            if (init !== depDate ) {
              selected = depDate;
            }
            return { data, selected, init: depDate }
          }
          loadData(setState, url, false, partialUpdFn);
        } else {
          // Calculate the min/max dates
          const minDate = dateToString(dateAddDays(new Date(), minBookingStartDays));
          const maxDate = dateToString(dateAddDays(new Date(), maxBookingStartDays));
          setState(s => ({...s, selected: depDate && s.init !== depDate ? depDate : s.selected, minDate, maxDate}));
        }
      } else {
        setState(s => ({...s, error: false, data: null, selected: null}));
      }
    }
  }, [searchType, depDate, departures, destinations]);

  return [state, setState];
}


function useReturnDates({ searchType, retDate }, departures, destinations, departureDates) {
  const [state, setState] = useState({
    init: null,
    display: true,
    loading: false,
    error: false,
    data: null,
    selected: null,
    minDate: null,
    maxDate: null,
  });

  /** Load data on init and select if present in URL params, or default */
  useEffect(() => {
    if (searchType) {
      if ((!departures.display ||departures?.selected) && destinations?.selected && departureDates?.selected) {
        const { loadDates, minBookingDurationDays, maxBookingDurationDays } = SEARCH_CONFIG[searchType];
        if (loadDates) {
          const url = API_URLS.returnDates(searchType, departures?.selected, destinations.selected, departureDates.selected);
          const partialUpdFn = (data, {init, selected}) => {
            if (init !== retDate ) { selected = retDate; }
            return { data, selected, init: retDate }
          }
          loadData(setState, url, false, partialUpdFn);
        } else {
          // Calculate the min/max dates
          const minDate = dateToString(dateAddDays(new Date(departureDates.selected), minBookingDurationDays));
          const maxDate = dateToString(dateAddDays(new Date(departureDates.selected), maxBookingDurationDays));
          setState(s => ({...s, selected: retDate && s.init !== retDate ? retDate : s.selected, minDate, maxDate}));
        }
      } else {
        setState(s => ({...s, error: false, data: null, selected: null}));
      }
    }
  }, [searchType, departures, destinations, departureDates, retDate]);

  return [state, setState];
}


function useNumberOfDays(departureDates, returnDates) {
  return useMemo(() => {
    if (!departureDates.selected || !returnDates.selected) {
      return null;
    }
    const start = new Date(departureDates.selected);
    const end = new Date(returnDates.selected);
    return dateDiffInDays(start, end);
  }, [departureDates.selected, returnDates.selected]);
}


function useGuests({ searchType, adl, chd}) {
  const [adults, setAdults] = useState(null);
  const [children, setChildren] = useState(null);

  useEffect(() => {
    if (searchType) {
      const {defaultAdults} = SEARCH_CONFIG[searchType];
      if (!adults) setAdults(adl || defaultAdults);
      if (!children && chd) setChildren(chd);
    }
  }, [searchType, adl, chd, adults, children]);

  const {
    defaultAdults,
    minNumAdults,
    maxNumAdults,
    minNumChildren,
    maxNumChildren,
  } = searchType ? SEARCH_CONFIG[searchType] : {};

  return {
    adults: adults || defaultAdults,
    children: children || [],
    setAdults,
    setChildren,
    minNumAdults,
    maxNumAdults,
    minNumChildren,
    maxNumChildren,
  }
}


function isLoading(departures, destinations, departureDates, returnDates) {
  return departures?.loading || destinations.loading || departureDates.loading || returnDates.loading || false;
}


function useValidation({searchType}, departures, destinations, departureDates, returnDates, {adults, children}) {
  const [valid, errors] = useMemo(() => {
    if (searchType) {
      const {
        minNumAdults,
        maxNumAdults,
        minNumChildren,
        maxNumChildren,
      } = SEARCH_CONFIG[searchType];
      const errors = [];
      if (departures && !departures?.selected) errors.push('departure');
      if (!destinations.selected) errors.push('destination');
      if (!departureDates.selected) errors.push('departureDate');
      if (!returnDates.selected) errors.push('returnDate');
      if (!adults || adults < minNumAdults || adults > maxNumAdults) errors.push('adults');
      if (!children || children.length < minNumChildren || children.length > maxNumChildren) errors.push('children');
      return [errors.length === 0, errors];
    }
    return [false, []];
  }, [searchType, departures, destinations, departureDates, returnDates, adults, children]);

  return { valid, errors };
}

function useSearchFunction({searchType}, departures, destinations, departureDates, returnDates, {adults, children}) {
  const history = useHistory();
  return useCallback(() => {
    const url = NAV_URLS.search(searchType, departures?.selected, destinations.selected, departureDates.selected, returnDates.selected, adults, children);
    history.push(url);
  }, [searchType, departures?.selected, destinations.selected, departureDates.selected, returnDates.selected, adults, children, history]);
}


export function useSearchBox(userSearchType) {
  const params = useCustomUrlParams(userSearchType);

  const [departures, setDepartures] = useDepartures(params);
  const [destinations, setDestinations] = useDestinations(params, departures);
  const [departureDates, setDepartureDates] = useDepartureDates(params, departures, destinations);
  const [returnDates, setReturnDates] = useReturnDates(params, departures, destinations, departureDates);
  const numberOfDays = useNumberOfDays(departureDates, returnDates);
  const guests = useGuests(params);
  const loading = isLoading(departures, destinations, departureDates, returnDates);
  const { valid, errors } = useValidation(params, departures, destinations, departureDates, returnDates, guests);
  const doSearch = useSearchFunction(params, departures, destinations, departureDates, returnDates, guests);

  const setterFn = useCallback(e => {
    return s => ({...s, selected: e?.target ? e.target.value : e});
  }, []);
  const setDeparture = useCallback(e => setDepartures(setterFn(e)), [setDepartures, setterFn]);
  const setDestination = useCallback(e => setDestinations(setterFn(e)), [setDestinations, setterFn]);
  const setDepartureDate = useCallback(e => setDepartureDates(setterFn(e)), [setDepartureDates, setterFn]);
  const setReturnDate = useCallback(e => setReturnDates(setterFn(e)), [setReturnDates, setterFn]);

  return {
    params,
    loading,
    departures : departures || { display: false},
    setDeparture,
    destinations,
    setDestination,
    departureDates,
    setDepartureDate,
    returnDates,
    setReturnDate,
    numberOfDays,
    guests,
    valid,
    errors,
    canSearch: !loading && valid,
    doSearch,
  }
}

export default useSearchBox;
