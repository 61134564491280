import React, {useState} from "react";
import CharterSearchBox from "../search-box/CharterSearchBox";
import HotelSearchBox from "../search-box/HotelSearchBox";
import BusCharterSearchBox from "../search-box/BusCharterSearchBox";
import ProgramSearchBox from "../search-box/ProgramSearchBox";
import {useTranslation} from 'react-i18next/hooks';
import i18n from '../../util/i18n';

const tabs = [
    {id: 'charter', name: 'CHARTER'},
    {id: 'hotel', name: 'HOTEL'},
    {id: 'busCharter', name: 'BUS CHARTER'},
    {id: 'programs', name: 'PROGRAMMES'},
];

function SearchArea() {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const isActive = tab => {
        return activeTab === tab;
    };

    useTranslation();

    return (
        <>
            <ul className="nav mix-pills nav-pills mb-3" id="pills-tab" role="tablist">
                {tabs.map(tab => (
                    <li className="nav-item" key={tab.id}>
              <span className={'nav-link' + (isActive(tab) ? ' active' : '')}
                    aria-controls="pills-hotel"
                    aria-selected="true"
                    role="tab"
                    onClick={() => {
                        toggleTab(tab)
                    }}
              >{i18n.t(tab.name)}</span>
                    </li>
                ))}
            </ul>
            {activeTab.id === 'charter' && <CharterSearchBox/>}
            {activeTab.id === 'hotel' && <HotelSearchBox/>}
            {activeTab.id === 'busCharter' && <BusCharterSearchBox/>}
            {activeTab.id === 'programs' && <ProgramSearchBox/>}
        </>
    );
}


export default SearchArea;
