import axios from 'axios';
import {endpointV1} from "../const/api-config";

const Axios = axios.create({
  baseURL: endpointV1,
});

Axios.interceptors.request.use(
  async config => {
    const id_token = await localStorage.getItem('id_token');
      config.headers.Accept = 'application/json';
      if (id_token) {
        config.headers.Authorization = `Bearer ${id_token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error)
  });

export default Axios;

