const DEFAULT_CACHE_DURATION = 300;

const now = () => Math.floor(new Date().getTime() / 1000);

class Cached {

  constructor () {
    this.cache = new Map();
  }

  clearOld () {
    for (const [key, val] in this.cache.entries()) {
      if (val.expires < now()) {
        this.cache.delete(key)
      }
    }
  }

  has (key) {
    this.clearOld();
    return this.cache.has(key);
  }

  get (key) {
    this.clearOld();
    return this.cache.get(key)?.value;
  }

  set (key, value, duration) {
    duration = duration || DEFAULT_CACHE_DURATION;
    this.cache.set(key, {
      expires: now() + duration,
      value
    });
  }

  log () {
    console.log(this.cache.entries());
  }
}

export default new Cached();
