import SEARCH_CONFIG from "./search";

function assertHasDeparture(searchType, departure) {
  if(!departure) throw Error(`Departure was not provided for search type "${searchType}"`);
}

function assertHasDestination(searchType, destination) {
  if(!destination) throw Error(`Destination was not provided for search type "${searchType}"`);
}

function assertHasDepartureDate(searchType, departureDate) {
  if(!departureDate) throw Error(`Departure date was not provided for search type "${searchType}"`);
}

function assertHasReturnDate(searchType, returnDate) {
  if(!returnDate) throw Error(`Return date was not provided for search type "${searchType}"`);
}

function assertHasHotelId(searchType, hotelId) {
  if(!hotelId) throw Error(`Hotel id date was not provided for search type "${searchType}"`);
}

function assertHasAcommodationId(searchType, accommodationId) {
  if(!accommodationId) throw Error(`Hotel id date was not provided for search type "${searchType}"`);
}

function urlWithDeparture(searchType, urlType, departure) {
  const url = [searchType, urlType];
  if (SEARCH_CONFIG[searchType].hasDepartures) {
    assertHasDeparture(searchType, departure);
    url.push(departure.id);
  }
  return url;
}

function departures(searchType) {
  if (!SEARCH_CONFIG[searchType].hasDepartures) {
    throw Error(`The provided search type "${searchType}" has no departures.`)
  }
  return [searchType, 'departures'].join('/');
}

function destinations(searchType, departure) {
  return urlWithDeparture(searchType, 'destinations', departure).join('/');
}

function departureDates(searchType, departure, destination) {
  const url = urlWithDeparture(searchType, 'departure-dates', departure);
  assertHasDestination(searchType, destination);
  return [...url, destination.type, destination.id].join('/');
}

function returnDates(searchType, departure, destination, departureDate) {
  const url = urlWithDeparture(searchType, 'return-dates', departure);
  assertHasDestination(searchType, destination);
  assertHasDepartureDate(searchType, departureDate);
  return [...url, destination.type, destination.id, departureDate].join('/');
}

function results(searchType, departure, destination, departureDate, returnDate, adl, chd) {
  let url = urlWithDeparture(searchType, 'results', departure);
  assertHasDestination(searchType, destination);
  assertHasDepartureDate(searchType, departureDate);
  assertHasReturnDate(searchType, returnDate);
  url = [...url, destination.type, destination.id, departureDate, returnDate, adl]
  if (chd?.length > 0) {
    console.log('Adding:', chd);
    url.push(chd);
  }
  return url.join('/');
}

function searchHotelDetails(searchType, departure, destination, departureDate, returnDate, hotelId) {
  const url = urlWithDeparture(searchType, 'results', departure);
  assertHasDestination(searchType, destination);
  assertHasDepartureDate(searchType, departureDate);
  assertHasReturnDate(searchType, returnDate);
  assertHasHotelId(searchType, hotelId);
  return [...url, destination.type, destination.id, departureDate, returnDate, hotelId].join('/');
}

function searchHotelAccommodationDetails(searchType, departure, destination, departureDate, returnDate, hotelId, accommodationId) {
  const url = urlWithDeparture(searchType, 'results', departure);
  assertHasDestination(searchType, destination);
  assertHasDepartureDate(searchType, departureDate);
  assertHasReturnDate(searchType, returnDate);
  assertHasHotelId(searchType, hotelId);
  assertHasAcommodationId(searchType, accommodationId);
  return [...url, destination.type, destination.id, departureDate, returnDate, hotelId, accommodationId].join('/');
}

const API_URLS = {
  departures,
  destinations,
  departureDates,
  returnDates,
  results,
  searchHotelDetails,
  searchHotelAccommodationDetails,
}

export default API_URLS;
