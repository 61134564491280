import Axios from "../config/axios";
import {endpointV2} from "../const/api-config";
import Cached from "./cached";

const DEFAULT_CACHE_DURATION = 300;

const prefixUrl = url => {
  if (url.startsWith('/')) {
    url = url.substr(1);
  }
  return url.includes('://') ? url : `${endpointV2}${url}`;
}

class _Api {
  get(url, cached = false, params) {
    url = prefixUrl(url);
    if (cached) {
      const response = Cached.get(url);
      if (response) {
        return Promise.resolve(response);
      } else {
        return Axios.get(url, params)
          .then(result => result.data)
          .then(data => {
            Cached.set(url, data, DEFAULT_CACHE_DURATION);
            return data;
          });
      }
    } else {
      return Axios.get(url, params).then(result => result.data);
    }
  }

  post(url, data) {
    return Axios.post(prefixUrl(url), data).then(result => result.data);
  }

  put(url, data) {
    return Axios.put(prefixUrl(url), data).then(result => result.data);
  }

  patch(url, data) {
    return Axios.patch(prefixUrl(url), data).then(result => result.data);
  }

  delete(url) {
    return Axios.delete(prefixUrl(url));
  }
}

const Api = new _Api();

export default Api;
