import React from 'react';
import HomeSection from "../components/shared/HomeSection";
import SubscribeSection from "../components/SubscribeSection/SubscribeSection";
import OfferSection3 from "../components/OfferSectionsTypes/OfferSection3";
import HomePageLayout from "../components/layouts/HomePageLayout";
// import {theme} from "../themes/theme";

export default function HomeRoute() {
    //const {button: { color }} = theme;

    return (
      <HomePageLayout>
        {/*<p>Value from theme: {color}</p>*/}
        <HomeSection/>
        <SubscribeSection/>
        <OfferSection3/>
      </HomePageLayout>
    );
}
