import i18n from "i18next";
import {initReactI18next} from "react-i18next/hooks";
import translationEN from '../assets/i18n/en';
import translationMK from '../assets/i18n/mk';
import translationSQ from '../assets/i18n/sq';
import translationSR from '../assets/i18n/sr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            mk: {
                translation: translationMK
            },
            sq: {
                translation: translationSQ
            },
            sr: {
                translation: translationSR
            },
        },
        lng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
