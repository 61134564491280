import {createContext, useContext, useState, useEffect} from "react";
import Axios from "../config/axios";

const token = localStorage.getItem('id_token') || null;


const UserContext = createContext({token});

export const useUser = () => useContext(UserContext);

export function ProvideUserContext({children}) {
  const user = useProvideUser();
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

function useProvideUser() {
  const [user, setUser] = useState({token});
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    // Response interceptor for API calls
    Axios.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      // const originalRequest = error.config;
      if (error.response.status === 401) {
        setUser({token: null});
      }
      return Promise.reject(error);
    });
  }, []);

  const login = (credentials) => {
    Axios.post('/login', credentials)
      .then(data => {
        if (data.access_token) {
          localStorage.setItem('id_token', data.access_token);
          setUser({
            token: data.access_token,
          });
          setLoginError(false);
        } else if (data.error) {
          setUser({token: null});
          setLoginError(true);
        }
      })
      .catch(() => {
        setLoginError(true);
      });
  };

  const logout = () => {
    localStorage.removeItem('id_token');
    setUser({token: null});
    setLoginError(false);
  }

  // const user = useContext(ThemeContext);
  return {
    ...user,
    loggedIn: user.token != null,
    loginError,
    login,
    logout
  };
}
