
const search = (searchType, departure, destination, departureDate, destinationDate, adults, children) => {
  const url = ['/search', searchType];
  if (departure) url.push(departure.id);
  url.push(destination.type, destination.id, departureDate, destinationDate, adults, children.join(',') || 0);
  return url.join('/');
};

const details = (searchType, departure, destination, departureDate, destinationDate, adults, children, hotelId, accommodationId) => {
  const url = ['/details', searchType];
  if (departure) url.push(departure.id);
  url.push(destination.type, destination.id, departureDate, destinationDate, adults, children.join(',') || 0, hotelId);
  if (accommodationId) url.push(accommodationId);
  return url.join('/');
};

const detailsFromParams = ({searchType, depId, destType, destId, depDate, retDate, adl, chd}, hotelId, accommodationId) =>
    details(searchType, {id: depId}, {type: destType, id: destId}, depDate, retDate, adl, chd, hotelId);

const booking = (searchType, departure, destination, departureDate, destinationDate, adults, children, hotelId, accommodationId) => {
  const url = ['/booking', searchType];
  if (departure) url.push(departure.id);
  url.push(destination.type, destination.id, departureDate, destinationDate, adults, children.join(',') || 0, hotelId, accommodationId);
  return url.join('/');
}

const bookingFromParams = ({searchType, depId, destType, destId, depDate, retDate, adl, chd}, hotelId, accommodationId) =>
  booking(searchType, {id: depId}, {type: destType, id: destId}, depDate, retDate, adl, chd, hotelId, accommodationId);


const NAV_URLS = {
  home: () => '/',
  search,
  details,
  detailsFromParams,
  booking,
  bookingFromParams,
};

export default NAV_URLS;
