const SEARCH_CONFIG = {
  order: ['charter', 'hotel', 'program', 'bus-charter'],
  charter: {
    enabled: true,
    hasDepartures: true,
    cacheDepartures: true,
    cacheDestinations: true,
    loadDates: true,
    minNumAdults: 1,
    defaultAdults: 2,
    maxNumAdults: 6,
    minNumChildren: 0,
    maxNumChildren: 3,
    minBookingStartDays: 1,
    maxBookingStartDays: 365,
    minBookingDurationDays: 3,
    maxBookingDurationDays: 21,
  },
  hotel: {
    enabled: true,
    hasDepartures: false,
    cacheDestinations: true,
    loadDates: false,
    minNumAdults: 1,
    defaultAdults: 2,
    maxNumAdults: 6,
    minNumChildren: 0,
    maxNumChildren: 3,
    minBookingStartDays: 1,
    maxBookingStartDays: 365,
    minBookingDurationDays: 3,
    maxBookingDurationDays: 30,
  },
  program: {
    enabled: true,
    hasDepartures: false,
    cacheDestinations: true,
    loadDates: true,
    minNumAdults: 1,
    defaultAdults: 2,
    maxNumAdults: 6,
    minNumChildren: 0,
    maxNumChildren: 3,
    minBookingStartDays: 2,
    maxBookingStartDays: 365,
    minBookingDurationDays: 3,
    maxBookingDurationDays: 30,
  },
  'bus-charter': {
    enabled: true,
    hasDepartures: true,
    cacheDepartures: true,
    cacheDestinations: false,
    loadDates: true,
    minNumAdults: 1,
    defaultAdults: 2,
    maxNumAdults: 6,
    minNumChildren: 0,
    maxNumChildren: 3,
    minBookingStartDays: 1,
    maxBookingStartDays: 365,
    minBookingDurationDays: 3,
    maxBookingDurationDays: 21,
  },
};

export default SEARCH_CONFIG;
