import React from "react";
import {Route, Switch} from "react-router-dom";
import loadable from "@loadable/component";
import ExampleSearchBox from "../hooks/ExampleSearchBox";
import HomeRoute from "./home";

// const Home = loadable(() => import(/* webpackChunkName: "home" */ './home'));
const Search = loadable(() => import(/* webpackChunkName: "search" */ './search'));
const Details = loadable(() => import(/* webpackChunkName: "details" */ './details'));
const Booking = loadable(() => import(/* webpackChunkName: "booking" */ './booking'));
const BookingView = loadable(() => import(/* webpackChunkName: "booking-view" */ './booking-view'));
const StaticPage = loadable(() => import(/* webpackChunkName: "static" */ './static'));
const LoginPage = loadable(() => import(/* webpackChunkName: "login" */ './login'));


export default function Pages() {
  return (
      <Switch>
        <Route exact path="/" component={HomeRoute}/>
        <Route exact path="/search-box/:searchType/:depId/:destType/:destId/:depDate/:retDate/:adl/:chd">
          <ExampleSearchBox/>
        </Route>
        <Route exact path="/search-box/:searchType/:destType/:destId/:depDate/:retDate/:adl/:chd">
          <ExampleSearchBox/>
        </Route>
        <Route path="/search-box/charter">
          <ExampleSearchBox type="charter"/>
        </Route>
        <Route path="/search-box/hotel">
          <ExampleSearchBox type="hotel"/>
        </Route>
        <Route path="/search" component={Search}/>
        <Route path="/details" component={Details}/>
        <Route path="/booking" component={Booking}/>
        <Route path="/booking-view" component={BookingView}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/:page" component={StaticPage}/>
      </Switch>
  );
}
