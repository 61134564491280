import useSearchBox from "./useSearchBox";
import {Spinner} from "reactstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import GuestsPopup from "../components/search-box/GuestsPopup";


function ExampleSearchBox({type}) {
  const searchConfig = useSearchBox(type);
  const {
    params,
    // loading,
    departures,
    setDeparture,
    destinations,
    setDestination,
    departureDates,
    setDepartureDate,
    returnDates,
    setReturnDate,
    guests,
    // valid,
    // errors,
    canSearch,
    doSearch,
  } = searchConfig;

  if (!params?.searchType) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Search: {params.searchType}</h1>
      <div className="d-flex flex-column flex-lg-row">
        <div className="d-flex flex-column flex-md-row">
          {departures.display && (
            <Typeahead
              id="departure"
              disabled={!departures.data}
              options={departures.data || []}
              onChange={e => setDeparture(e.length > 0 ? e[0] : null)}
              selected={departures.selected ? [departures.selected] : []}
              labelKey="name"
              placeholder="To">
              {({selected}) => (
                <div className="rbt-aux">
                  {!selected && <Spinner animation="grow" size="sm"/>}
                </div>
              )}
            </Typeahead>
          )}
          <Typeahead
            id="destination"
            disabled={!destinations.data}
            options={destinations.data || []}
            onChange={e => setDestination(e.length > 0 ? e[0] : null)}
            selected={destinations.selected ? [destinations.selected] : []}
            labelKey="name"
            placeholder="To">
            {({selected}) => (
              <div className="rbt-aux">
                {!selected && <Spinner animation="grow" size="sm"/>}
              </div>
            )}
          </Typeahead>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <select id="departure-date"
                  value={departureDates.selected || ''}
                  disabled={(!departureDates.data && !departureDates.minDate) || departureDates.loading}
                  onChange={e => setDepartureDate(e)}>
            <option/>
            {departureDates.data?.map(it =>
              <option key={it} value={it}>{it}</option>
            )}
          </select>

          <select id="return-date"
                  value={returnDates.selected || ''}
                  disabled={!returnDates.data || returnDates.loading}
                  onChange={e => setReturnDate(e)}>
            <option/>
            {returnDates.data?.map(it =>
              <option key={it} value={it}>{it}</option>
            )}
          </select>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <GuestsPopup guests={guests}/>
          <button disabled={!canSearch} onClick={doSearch}>Search</button>
        </div>
      </div>
    </div>
  );
}

export default ExampleSearchBox;
