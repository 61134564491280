import React, {useRef} from 'react';
import './Popup.scss';
import cx from 'classnames';
import {useState} from "react";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";


function Popup({toggle, children, right= false}) {
  const ref = useRef();
  const [visible, setVisible] = useState();
  useOnClickOutside(ref, () => {
    setVisible(false);
  });
  return (
    <div ref={ref} onClick={() => setVisible(!visible)} style={{position: 'relative'}}>
      {toggle}
      <div onClick={e => e.stopPropagation()} className={cx("popup", {right, visible})}>
        {children}
      </div>
    </div>
  );
}

export default Popup;
