import React from 'react';
import bg from '../../assets/images/etc/bg-wallpaper1.jpg';
import SearchArea from "../SearchArea/SearchArea";

const HomeSection = () => {
    const sectionStyle = {
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover'
    }
    return (
        <section className="home_section slide-1 p-0">
            <div>
                <div className="home home_mobile" id="block" style={sectionStyle}>
                <div className="container custom-container mix-layout-section">
                    <div className="row">
                        <div className="col-xl-10 m-auto">
                            <div className="home-content mix-layout smaller-content">
                                <div className="bg-transparent">
                                    <h1>Where do you want to go?</h1>
                                    <h3>Experience world class services trip in Japan featured</h3>
                                    <SearchArea/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection;
