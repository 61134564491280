import GlobalLayout from "./GlobalLayout";
import Header from "./Header";

function HomePageLayout({children}) {
  return (
    <GlobalLayout>
      <Header overlay/>
      {children}
    </GlobalLayout>
  );
}

export default HomePageLayout;
