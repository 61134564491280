import {FaFlag, FaMap, FaCity, FaPlane, FaHotel} from "react-icons/fa";
import {Spinner} from "reactstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import GuestsPopup from "./GuestsPopup";
import useSearchBox from "../../hooks/useSearchBox";
import DateSelector from "./DateSelector";
import './customSearchBox.scss'


function HotelSearchBox() {
    const searchConfig = useSearchBox('hotel');
    const {
        // params,
        // loading,
        destinations,
        setDestination,
        departureDates,
        setDepartureDate,
        returnDates,
        setReturnDate,
        numberOfDays,
        guests,
        // valid,
        // errors,
        canSearch,
        doSearch,
    } = searchConfig;

    const destinationTypeIcon = (type, props) => {
        switch (type) {
            case 'country':
                return <FaFlag {...props} />;
            case 'region':
                return <FaMap {...props} />;
            case 'city':
                return <FaCity {...props} />;
            case 'airport':
                return <FaPlane {...props} />;
            default:
                return <FaHotel {...props} />;
        }
    };

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-hotel" role="tabpanel"
                 aria-labelledby="pills-hotel-tab">
                <div className="search-panel">
                    <div className="search-section">
                        <div className="search-box">
                            <div className="left-part">
                                <div className="search-body title-hotel">
                                    <h6>To</h6>
                                    <Typeahead
                                        id="destination"
                                        disabled={!destinations.data}
                                        options={destinations.data || []}
                                        onChange={e => setDestination(e.length > 0 ? e[0] : null)}
                                        selected={destinations.selected ? [destinations.selected] : []}
                                        labelKey="name"
                                        renderMenuItemChildren={(option) => {
                                            return <div className="d-flex flex-row">
                                                <div
                                                    className="pr-1">{destinationTypeIcon(option.type, {className: "mr-2"})}</div>
                                                <div className="d-flex flex-column flex-grow-1">
                                                    <strong>{option.name}</strong>
                                                    <small>{option.add}</small>
                                                </div>
                                            </div>
                                        }}
                                        placeholder="To">
                                        {({_, selected}) => (
                                            <div className="rbt-aux">
                                                {!selected && <Spinner animation="grow" size="sm"/>}
                                            </div>
                                        )}
                                    </Typeahead>
                                </div>
                                <div className="search-body">
                                    <h6>Departure</h6>
                                    <DateSelector className="responsiveDatepicker" dates={departureDates}
                                                  setDate={setDepartureDate}/>
                                </div>
                                <div className="search-body">
                                    <h6>Return</h6>
                                    <DateSelector className="responsiveDatepicker" dates={returnDates}
                                                  setDate={setReturnDate}/>
                                </div>
                                <div className="search-body">
                                    <h6>guests</h6>
                                    <GuestsPopup guests={guests}/>
                                </div>
                                <div className="search-body">
                                    {numberOfDays && <div>{numberOfDays} nights</div>}
                                </div>
                                <div className="search-body btn-search">
                                    <button disabled={!canSearch} onClick={doSearch}
                                            className="btn btn-solid color1 customSearchButton">Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HotelSearchBox;
