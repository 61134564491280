import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './util/i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ID_PREFIX = 'zt-';
const decoupledAttributes = ['lang','charter', 'hotel', 'bus-charter', 'program'];
const homeAttributes = [...decoupledAttributes,'charter', 'hotel', 'bus-charter', 'program'];
const mountingPoints = [
  {id: 'root', props: {}},
  {id: 'home', props: {detached: true, searchBox: true}, attributes: homeAttributes},
  {id: 'search', props: {detached: true, searchResults: true}, attributes: decoupledAttributes},
  {id: 'details', props: {detached: true, details: true}, attributes: decoupledAttributes},
  {id: 'booking', props: {detached: true, booking: true}, attributes: decoupledAttributes},
];

for (let mountingPoint of mountingPoints) {
  const {id, props, attributes = []} = mountingPoint;
  const element = document.getElementById(ID_PREFIX + id);
  if (element) {
    const attrs = {};
    attributes.filter(it => element.hasAttribute(it)).forEach(it => attrs[it] = true);
    ReactDOM.render(
      <React.StrictMode>
        <App {...{...attrs, ...props}} />
      </React.StrictMode>,
      element
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
