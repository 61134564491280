import React, {useEffect, useState} from 'react';
import Api from "../../classes/api";

function OfferSection3() {
    const [offers, setOffers] = useState(null);

    useEffect(() => {
        Api.get('/offers').then(setOffers);
    }, []);

    if (!offers) {
        return false;
    }

    return (
        <section className="category-sec ratio3_2 section-b-space">
            <div className="container">
                <div className="title-1 title-5">
                    <span className="title-label">new offer</span>
                    <h2>most popular tours</h2>
                    <p>Trips, experiences, and places. All in one service.</p>
                </div>
                <div className="row arrow-classic">
                    {offers.map(offer => (
                        <div key={offer.id} className="col-lg-3">
                            <div className="category-box">
                                <div className="img-category">
                                    <div className="side-effect"></div>
                                    <div>
                                        <img src={offer.image.medium} alt=""
                                             className="img-fluid bg-img"/>
                                    </div>
                                    <div className="top-bar">
                                        <span className="offer">offer</span>
                                        <h5>
                                            <del>$999</del>
                                            {offer.price}€
                                        </h5>
                                    </div>
                                    <div className="like-cls">
                                        <i className="fas fa-heart"><span className="effect"></span></i>
                                    </div>
                                </div>
                                <div className="content-category">
                                    <div className="top">
                                        <h3>{offer.title}</h3>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                    <p>{offer.location}</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur est.</p>
                                    <h6>5 days | 6 nights <span> 2 person</span></h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OfferSection3;
