import {useMemo} from "react";
import {useParams} from "react-router-dom";

export default function useUrlParams(searchType = null) {
  const routeParams = useParams();
  return useMemo(() => {
    const { searchType: urlSearchType, depId, destType, destId, depDate, retDate, adl, chd } = routeParams;
    return {
      searchType: searchType || urlSearchType,
      depId: depId && parseInt(depId, 10),
      destType,
      destId: destId && parseInt(destId, 10),
      depDate,
      retDate,
      adl: adl && parseInt(adl, 10),
      chd: chd && chd.split(',').filter(it => it > 0),
    }
  }, [routeParams, searchType]);
}
