import {useState} from "react";
import Api from "../../classes/api";
import NewsletterFormSuccess from "./NewsletterFormSuccess";
import NewsletterForm from "./NewsletterForm";

function SubscribeSection() {
    const [email, setEmail] = useState('');
    const [showSuccessBlock, setShowSuccessBlock] = useState(false);


    const handleEmailChange = e => {
        setEmail(e.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();

        const body = {
            email: email
        }

        Api.post("static/subscribe", body)
            .then(() => {
            });

        setShowSuccessBlock(true);
    }


    return (
        <section className="subscribe_section medium-section">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="subscribe-detail">
                            <div>
                                <h2>subscribe our news <span>our news</span></h2>
                                <p>Subscribe and receive our newsletters to follow the news about our fresh and
                                    fantastic
                                    products</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-6">
                        <div className="input-section">
                            {showSuccessBlock ? <NewsletterFormSuccess email={email}/> :
                                <NewsletterForm email={email} handleEmailChange={handleEmailChange}
                                                handleSubmit={handleSubmit}/>}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubscribeSection;
