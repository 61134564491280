import React from 'react';

const NewsletterFormSuccess = ({email}) => {
    return (
        <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Well done!</h4>
            <p className="mb-0">
                We've successfully added <strong>{email}</strong> to our
                newsletter. Thank you.</p>
        </div>
    );
};

export default NewsletterFormSuccess;
