import logo from '../../assets/images/etc/etc-logo3.png';
import {useTranslation} from 'react-i18next/hooks';
import i18n from '../../util/i18n';
import {Link} from "react-router-dom";
import languages from "../../config/languages";
import {useUser} from "../../context/user-context";
import bg from '../../assets/images/etc/bg-wallpaper1.jpg';
import classNames from 'classnames';
import {useState, useEffect} from 'react';

const Header = ({overlay = false}) => {
    useTranslation();
    const { loggedIn, logout } = useUser();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    }

    const [isNavVisible, setIsNavVisible] = useState(true);

    useEffect(()=> {
        const size = window.innerWidth;
        if(size < 700) {
            setIsNavVisible(false);
        } else {
            setIsNavVisible(true);
        }
    }, []);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    }


    const sectionStyle = {
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        minHeight: '90px',
    }

    return (
      <header style={overlay ? {} : sectionStyle} className={classNames( {'position-absolute': overlay})}>
        <div className="overlay-black">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="menu">
                            <Link to="/" className="brand-logo">
                                <img src={logo} alt="" style={{width: '120px'}}
                                     className="img-fluid lazyload"/>
                            </Link>

                            <nav>
                                <div className="main-navbar">

                                    <div id="mainnav">
                                        <div className="toggle-nav"  onClick={toggleNav} ><i className="fa fa-bars sidebar-bar"/></div>
                                        <div className="menu-overlay"/>
                                        {isNavVisible &&
                                        <ul className="nav-menu">
                                            <li className="back-btn">
                                                <div className="mobile-back text-right">
                                                    <span onClick={toggleNav}>Back</span>
                                                    <i aria-hidden="true" className="fa fa-angle-right pl-2"/>
                                                </div>
                                            </li>
                                            <li className="dropdown">
                                            <Link className="nav-link" to="/">
                                                {i18n.t('MENU_HOME')}
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <a href="!#" className="nav-link">{i18n.t('MENU_SERVICES')}</a>
                                                <ul className="nav-submenu menu-content">
                                                    <li><a href="http://erafly.com/amadeus/default.html"
                                                           target="blank"
                                                           className="menu-title">Flight Tickets</a>
                                                    </li>
                                                    <li><a href="http://erafly.com/rentacar/renta_c.asp"
                                                           target="blank"
                                                           className="menu-title">Rent a Car</a>
                                                    </li>
                                                    <li><a href="!#" className="menu-title">Sport</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <Link className="nav-link" to="/about-us">
                                                    {i18n.t('MENU_ABOUT_US')}
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link to="/contact-us" className="nav-link">{i18n.t('MENU_CONTACT')}</Link>
                                            </li>
                                            {loggedIn || (
                                              <li className="dropdown">
                                                <Link to="/login" className="nav-link">{i18n.t('MENU_LOGIN')}</Link>
                                              </li>
                                            )}
                                            {loggedIn && (
                                              <li className="dropdown">
                                                <a href="!#" className="nav-link">Profile</a>
                                                <ul className="nav-submenu menu-content">
                                                    <li><Link to='/booking-list'
                                                        className="menu-title">Bookings</Link>
                                                    </li>
                                                     <li><a href="!#" onClick={logout}
                                                        target="blank"
                                                        className="menu-title">{i18n.t('MENU_LOGOUT')}</a>
                                                     </li>

                                                </ul>
                                              </li>
                                            )}
                                        </ul>}
                                    </div>
                                </div>
                            </nav>
                            <ul className="header-right">
                                <li className="front-setting">
                                    <select onChange={e => changeLanguage(e.target.value)}>
                                        {languages.map(({code, short}) =>
                                          <option key={code} value={code} >{short}</option>
                                        )}
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </header>
    )
}
export default Header;

